import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import YouthImg from '../../static/img/Youth.png'
import InfantsImg from '../../static/img/Infants.png'
import ToddlersImg from '../../static/img/Toddlers.png'
import ChildrenImg from '../../static/img/Children.png'
import MiddleSchoolImg from '../../static/img/Middle School.png'
import HighSchoolImg from '../../static/img/High School.png'
import CollegeImg from '../../static/img/College.png'

export const YouthPageTemplate = ({ 
  backgroundimage, 
  infants,
  infantsinfo,
  toddlers,
  toddlersinfo,
  children,
  childreninfo,
  middleschool,
  middleschoolinfo,
  highschool,
  highschoolinfo,
  college,
  collegeinfo
}) => (
  
  <div style={{
    // backgroundImage: `url(${Pattern})`,
    // backgroundRepeat: 'repeat',
    // backgroundAttachment: 'scroll',
    // backgroundBlendMode: 'soft-light',
    // backgroundColor: '#00000079'

    // backgroundColor: '#4659A3'
  }}>
    {/* Top Background Image */}
    <div
      className="full-width-image margin-top-0"
      style={{
        backgroundImage: `url(${!!backgroundimage.childImageSharp ? backgroundimage.childImageSharp.fluid.src : backgroundimage})`,
        // backgroundColor: '#FFFFFF67'
      }}
    >
      <div
        style={{
          display: 'flex',
          height: '150px',
          lineHeight: '1',
          justifyContent: 'space-around',
          alignItems: 'left',
          flexDirection: 'column',
        }}
      >
        <img src={YouthImg} alt="Title" style={{ height: '300px' }} />
      </div>
    </div>

    {/* Google Fonts Import */}
    <link
      href="https://fonts.googleapis.com/css2?family=Crimson+Text&family=Poppins:wght@300&display=swap"
      rel="stylesheet"
    ></link>

    <div className='youth-margins'>
      
      {/* INFANTS */}
      {infants ?
        <div className='youth-category-box'>
          <img src={InfantsImg} style={{
            width: '250px'
          }} />
          <div className='youth-category-info'>{infantsinfo}</div>
        </div>
      : null}

      {/* TODDLERS */}
      {toddlers ?
        <div className='youth-category-box'>
          <img src={ToddlersImg} style={{
            width: '250px'
          }} />
          <div className='youth-category-info'>{toddlersinfo}</div>
        </div>
      : null}

      {/* CHILDREN */}
      {children ?
        <div className='youth-category-box'>
          <img src={ChildrenImg} style={{
            width: '250px'
          }} />
          <div className='youth-category-info'>{childreninfo}</div>
        </div>
      : null}

      {/* MIDDLE SCHOOL */}
      {middleschool ?
        <div className='youth-category-box'>
          <img src={MiddleSchoolImg} style={{
            width: '250px'
          }} />
          <div className='youth-category-info'>{middleschoolinfo}</div>
        </div>
      : null}

      {/* HIGH SCHOOL */}
      {highschool ?
        <div className='youth-category-box'>
          <img src={HighSchoolImg} style={{
            width: '250px'
          }} />
          <div className='youth-category-info'>{highschoolinfo}</div>
        </div>
      : null}

      {/* COLLEGE */}
      {college ?
        <div className='youth-category-box'>
          <img src={CollegeImg} style={{
            width: '250px'
          }} />
          <div className='youth-category-info'>{collegeinfo}</div>
        </div>
      : null}
    </div>
  </div>
)

YouthPageTemplate.propTypes = {
  backgroundimage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  infants: PropTypes.bool,
  infantsinfo: PropTypes.string,
  toddlers: PropTypes.bool,
  toddlersinfo: PropTypes.string,
  children: PropTypes.bool,
  childreninfo: PropTypes.string,
  middleschool: PropTypes.bool,
  middleschoolinfo: PropTypes.string,
  highschool: PropTypes.bool,
  highschoolinfo: PropTypes.string,
  college: PropTypes.bool,
  collegeinfo: PropTypes.string,
}

const YouthPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <YouthPageTemplate
        backgroundimage={frontmatter.backgroundimage}
        infants={frontmatter.infants}
        infantsinfo={frontmatter.infantsinfo}
        toddlers={frontmatter.toddlers}
        toddlersinfo={frontmatter.toddlersinfo}
        children={frontmatter.children}
        childreninfo={frontmatter.childreninfo}
        middleschool={frontmatter.middleschool}
        middleschoolinfo={frontmatter.middleschoolinfo}
        highschool={frontmatter.highschool}
        highschoolinfo={frontmatter.highschoolinfo}
        college={frontmatter.college}
        collegeinfo={frontmatter.collegeinfo}
      />
    </Layout>
  )
}

YouthPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default YouthPage

export const youthPageQuery = graphql`
  query YouthPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "youth-page" } }) {
      frontmatter {
        backgroundimage {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        infants
        infantsinfo
        toddlers
        toddlersinfo
        children
        childreninfo
        middleschool
        middleschoolinfo
        highschool
        highschoolinfo
        college
        collegeinfo
      }
    }
  }
`